<template>
  <v-dialog
    max-width="700"
    width="100%"
    :value="true"
    @click:outside="close"
    @keydown.esc="close"
    scrollable
  >
    <v-card :loading="loading">
      <v-card-title class="pa-0">
        <v-toolbar dark class="mb-2">
          <v-btn icon>
            <v-icon>mdi-calendar-text</v-icon>
          </v-btn>
          <v-toolbar-title>Worklog</v-toolbar-title>
          <v-spacer />
          <v-btn
            color="error"
            v-if="canEdit"
            @click="edit"
            fab
            small
            class="mr-4"
          >
            <v-icon>mdi-pen</v-icon>
          </v-btn>
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar v-if="worklog.person">
              <PortraitImage :value="worklog.person" />
            </v-list-item-avatar>
            <v-list-item-avatar v-else>
              <v-img :src="require('../../assets/default-user.png')"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-if="worklog.person">
                <PersonName :value="worklog.person" />
              </v-list-item-title>
              <v-list-item-title v-else>{{ worklog.author }}</v-list-item-title>
              <v-list-item-subtitle
                >{{ formatDate(worklog.date) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item> </v-list
        ><v-divider class="mb-4"></v-divider>
        <Markdown :value="worklog.content" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Markdown from "@/components/Markdown.vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";

export default {
  name: "WorklogDetails",
  components: { Markdown, PersonName, PortraitImage },
  props: ["id"],
  data() {
    return { worklog: {}, loading: false };
  },
  computed: {
    canEdit() {
      return this.$_profilePerson.code === this.worklog.author;
    },
  },
  methods: {
    edit() {
      this.$router.push({
        name: "WorklogEdit",
        params: { id: this.worklog.id },
      });
    },
    close() {
      this.$router.push({
        name: "Worklogs",
      });
    },
    formatDate(value) {
      const date = new Date(value);
      return date.toLocaleDateString("de-CH", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      });
    },
  },
  async created() {
    this.loading = true;
    this.worklog = await this.apiGet({
      resource: "worklog/worklog",
      id: this.id,
    });
    this.loading = false;
  },
};
</script>
